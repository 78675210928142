import { toast } from "react-toastify";
import axios from "axios";
import {
  exchangeBinance_spot,
  exchangeBinance_future,
  exchangeBinance_delivery,
  exchangeBinance_margin,
  exchangeBinance_option,
  exchangeDeribit_future,
  exchangeDeribit_option,
  exchangeDeribit_spot,
  deribitExchange_public,
  binanceSpot_public,
  binanceFuture_public,
  binanceMargin_public,
  binanceOption_public,
  binanceDelivery_public,
  loadPublicCCXT,
} from "./loginCCXT";
import { fetchCurrentPaused } from "./localstorage";

interface OrderData {
  type: string;
  side: string;
  icebergQty: number;
  quantity: number;
  price: number;
}

interface WalletData {
  classicAddress: string;
}

interface MarketData {
  market: string;
  insType: string;
  coin: string;
}

interface GreekData {
  [key: string]: any;
}

export const createNewOrder = async (
  selectedExchange: string,
  data: OrderData,
  symbol: string,
  insType: string
): Promise<any> => {
  const { type, side, icebergQty, quantity, price } = data;

  const inst = insType;
  if (selectedExchange === "binance") {
    if (
      exchangeBinance_spot &&
      exchangeBinance_future &&
      exchangeBinance_delivery &&
      exchangeBinance_margin &&
      exchangeBinance_option
    ) {
      try {
        let order;

        if (type.toLowerCase() === "iceberg") {
          console.log(
            `order is created in binance ${inst} with iceBerg qty, waiting for the confimation from API.`
          );
          order =
            inst === "spot"
              ? await exchangeBinance_spot.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price,
                  {
                    icebergQty,
                  }
                )
              : inst === "future"
              ? await exchangeBinance_future.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price,
                  {
                    icebergQty,
                  }
                )
              : inst === "margin"
              ? await exchangeBinance_margin.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price,
                  {
                    icebergQty,
                  }
                )
              : inst === "delivery"
              ? await exchangeBinance_delivery.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price,
                  {
                    icebergQty,
                  }
                )
              : await exchangeBinance_option.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price,
                  {
                    icebergQty,
                  }
                );
        } else {
          console.log(
            `order is created in binance ${inst}, waiting for the confimation from API.`
          );
          order =
            inst === "spot"
              ? await exchangeBinance_spot.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price
                )
              : inst === "future"
              ? await exchangeBinance_future.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price
                )
              : inst === "margin"
              ? await exchangeBinance_margin.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price,
                  {
                    icebergQty,
                  }
                )
              : inst === "delivery"
              ? await exchangeBinance_delivery.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price,
                  {
                    icebergQty,
                  }
                )
              : await exchangeBinance_option.create_order(
                  symbol,
                  type,
                  side,
                  +quantity,
                  +price,
                  {
                    icebergQty,
                  }
                );
        }

        if (order) {
          return order;
        }
      } catch (error: any) {
        const err = error.message.replace("binance", "").trim();
        const errJSON = err.charAt(0);

        console.error(err);

        if (errJSON === "{") {
          return { error: JSON.parse(err).msg };
        } else {
          return { error: err };
        }
      }
    } else {
      return { error: "No available exchange login for binance." };
    }
  } else {
    exchangeDeribit_future.setSandboxMode(true);

    if (
      exchangeDeribit_future &&
      exchangeDeribit_option &&
      exchangeDeribit_spot
    ) {
      try {
        const order =
          inst === "future"
            ? await exchangeDeribit_future.createOrder(
                symbol,
                type.toLowerCase(),
                side.toLowerCase(),
                +quantity,
                +price
              )
            : inst === "option"
            ? await exchangeDeribit_option.createOrder(
                symbol,
                type.toLowerCase(),
                side.toLowerCase(),
                +quantity,
                +price
              )
            : await exchangeDeribit_spot.createOrder(
                symbol,
                type.toLowerCase(),
                side.toLowerCase(),
                +quantity,
                +price
              );
        if (order) {
          return "success";
        }
      } catch (error: any) {
        const err = error.message.replace("deribit", "").trim();
        const errJSON = err.charAt(0);

        if (errJSON === "{") {
          return { error: JSON.parse(err).error.message };
        } else {
          return { error: err };
        }
      }
    } else {
      return { error: "No available exchange login for deribit." };
    }
  }
};

export const checkVerifyData = (
  e: React.FormEvent<HTMLFormElement>,
  verifyData: string,
  walletData: WalletData,
  setShowGenWallet: (show: boolean) => void
) => {
  e.preventDefault();
  if (
    verifyData.toLowerCase() ===
    walletData.classicAddress.substring(0, 5).toLowerCase()
  ) {
    toast.success("Wallet Address match");
    setShowGenWallet(false);
  } else {
    toast.error("Wallet doesn't match Please try again");
  }
};

export const getOrderBook = async (
  symbol: string,
  market: string,
  insType: string,
  numArr: number
): Promise<any> => {
  let orderbook;

  if (
    binanceSpot_public &&
    binanceDelivery_public &&
    binanceFuture_public &&
    binanceMargin_public &&
    binanceOption_public &&
    deribitExchange_public
  ) {
    if (market === "binance") {
      try {
        orderbook =
          insType === "spot"
            ? await binanceSpot_public.fetchOrderBook(symbol, numArr)
            : insType === "future"
            ? await binanceFuture_public.fetchOrderBook(symbol, numArr)
            : insType === "margin"
            ? await binanceMargin_public.fetchOrderBook(symbol, numArr)
            : insType === "option"
            ? await binanceOption_public.fetchOrderBook(symbol, numArr)
            : await binanceDelivery_public.fetchOrderBook(symbol, numArr);
      } catch (error: any) {
        const err = error.message.replace("binance", "").trim();
        const errJSON = err.charAt(0);

        if (errJSON === "{") {
          return { error: JSON.parse(err).msg };
        } else {
          return { error: err };
        }
      }
    } else {
      try {
        orderbook = await deribitExchange_public.fetch_order_book(symbol);
      } catch (error: any) {
        console.error(error);

        return { error: error.message };
      }
    }
  } else {
    loadPublicCCXT(false, false);
  }

  return orderbook;
};

export const getAccountBalance_binance = async (e: string): Promise<any> => {
  const currentExchange =
    e === "future"
      ? exchangeBinance_future
      : e === "spot"
      ? exchangeBinance_spot
      : e === "option"
      ? exchangeBinance_option
      : e === "margin"
      ? exchangeBinance_margin
      : exchangeBinance_delivery;

  if (currentExchange && currentExchange.apiKey) {
    try {
      const orders = await currentExchange.fetchBalance();

      return orders;
    } catch (error: any) {
      const err = error.message.replace("binance", "").trim();
      const errJSON = err.charAt(0);

      if (errJSON === "{") {
        console.error("Errror: ", JSON.parse(err)?.msg);
        return { error: JSON.parse(err)?.msg };
      } else {
        console.error("Errror: ", err);
        return { error: err };
      }
    }
  }
};

export const getAccountBalance = async (): Promise<any> => {
  if (exchangeBinance_spot && exchangeDeribit_future) {
    const balanceObj: any = {};

    try {
      balanceObj.binance = await exchangeBinance_spot.fetchBalance();
    } catch (error: any) {
      const err = error.message.replace("binance", "").trim();
      const errJSON = err.charAt(0);

      if (errJSON === "{") {
        console.error(JSON.parse(err).error.message);
        balanceObj.binance = {
          error: "There is an error fetching the account balance.",
        };
      } else {
        balanceObj.binance = {
          error: "There is an error fetching the account balance.",
        };
      }
    }

    try {
      const balanceETH = await exchangeDeribit_future.fetchBalance({
        currency: "ETH",
      });
      const balanceBTC = await exchangeDeribit_future.fetchBalance({
        currency: "BTC",
      });
      const balanceUSDC = await exchangeDeribit_future.fetchBalance({
        currency: "USDC",
      });

      balanceObj.deribit = {
        ...balanceObj.deribit,
        ...balanceBTC,
        ...balanceETH,
        ...balanceUSDC,
      };
    } catch (error: any) {
      const err = error.message.replace("deribit", "").trim();

      const errJSON = err.charAt(0);

      if (errJSON === "{") {
        balanceObj.deribit = { error: JSON.parse(err).error.message };
      } else {
        balanceObj.deribit = { error: err };
      }
    }

    return balanceObj;
  }
};

export const createIceBergOrder = async (
  selectedExchange: string,
  coin: string,
  marketTable: string,
  min: number,
  max: number,
  newOrder: any,
  orderID: string,
  firstOrder: boolean,
  newID: number,
  loggedinUser: any
): Promise<any> => {
  const exchange =
    marketTable === "future"
      ? exchangeDeribit_future
      : marketTable === "option"
      ? exchangeDeribit_option
      : exchangeDeribit_spot;

  let fetchOrder;

  const cancelled = loggedinUser?.metadata?.user_metadata?.cancelled;
  const paused = (await fetchCurrentPaused(loggedinUser?.sub)) || [];

  const filterC: any[] =
    cancelled?.filter((item: any) => item === orderID) || [];
  const filterP: any[] = paused?.filter((item: any) => item === +newID) || [];
  let filterP_inside: any = [];

  if (filterP.length) {
    return { warning: "Your trading got paused." };
  }

  if (filterC.length) {
    return { error: "cancelled" };
  }

  if (!firstOrder) {
    do {
      try {
        const pausedInside =
          (await fetchCurrentPaused(loggedinUser?.sub)) || [];

        filterP_inside = pausedInside.filter((item: any) => item === +newID);

        fetchOrder = await exchange.privateGetGetOrderState({
          order_id: orderID,
        });

        console.log(fetchOrder.result.order_state);
      } catch (error: any) {
        console.error(error.message);
        return;
      }
    } while (
      fetchOrder.result.order_state === "open" &&
      !filterP_inside.length
    );
  }

  const options = {
    method: "POST",
    headers: { "content-type": "application/json" },
    data: JSON.stringify(newOrder),
    url: `${process.env.NEXT_PUBLIC_BASE_URL}iceberg`,
  };
  let resData;

  try {
    resData = await axios(options);
  } catch (error: any) {
    console.error(error);
    return { error: error.message };
  }

  let position = await getPosition({
    market: selectedExchange,
    coin,
    insType: marketTable,
  });

  if (position?.result) {
    const pos = position?.result?.size;
    position = parseFloat(pos);
  }

  if (max !== undefined) {
    if (position > parseFloat(max.toString())) {
      return { warning: "Transaction terminated, position is more than max" };
    }
  } else {
    console.log("ERROR max is", max);
  }

  if (min !== undefined) {
    if (position < min) {
      return { warning: "Transaction terminated, position is less than min" };
    }
  } else {
    console.log("ERROR min is", min);
  }

  let order;
  if (resData.data) {
    try {
      const data = resData.data;
      order = await exchange.createOrder(
        data.instrument_name,
        data.type,
        data.direction,
        data.quantity,
        data.price
      );

      return { ...order, exit: data.Exit };
    } catch (error: any) {
      console.error(error.message);

      const err = error?.message?.replace("deribit", "").trim();
      const errJSON = err?.charAt(0);

      if (errJSON === "{") {
        return { error: `${JSON.parse(err)?.error?.message}` };
      } else {
        return { error: err };
      }
    }
  } else {
    return { error: "transaction terminated" };
  }
};

export const createTWAPOrder = async (
  selectedExchange: string,
  coin: string,
  marketTable: string,
  min: number,
  max: number,
  orderOption: any,
  orderID: string,
  firstOrder: boolean,
  newID: number,
  loggedinUser: any
): Promise<any> => {
  const exchange =
    marketTable === "future"
      ? exchangeDeribit_future
      : marketTable === "option"
      ? exchangeDeribit_option
      : exchangeDeribit_spot;

  let fetchOrder: any;

  const cancelled = loggedinUser?.metadata?.user_metadata?.cancelled || [];
  const paused = loggedinUser?.metadata?.user_metadata?.paused || [];

  const filterC = cancelled.filter((item: any) => item === orderID);
  const filterP = paused.filter((item: any) => item === +newID);
  let filterP_inside: any = [];

  if (filterP.length) {
    return { warning: "Your trading got paused." };
  }

  if (filterC.length) {
    return { error: "cancelled" };
  }

  const options = {
    method: "POST",
    headers: { "content-type": "application/json" },
    data: JSON.stringify(orderOption),
    url: `${process.env.NEXT_PUBLIC_BASE_URL}twap`,
  };
  let resData;

  try {
    resData = await axios(options);
  } catch (error: any) {
    console.error(error);
    return { error: error.message };
  }

  let position = await getPosition({
    market: selectedExchange,
    coin,
    insType: marketTable,
  });

  if (position?.result) {
    const pos = position?.result?.size;
    position = parseFloat(pos);
  }

  if (max !== undefined) {
    if (position > parseFloat(max.toString())) {
      return { warning: "Transaction terminated, position is more than max" };
    }
  } else {
    console.log("max is", max);
  }

  if (min !== undefined) {
    if (position < min) {
      return { warning: "Transaction terminated, position is less than min" };
    }
  } else {
    console.log("min is", min);
  }

  let order;
  if (resData.data) {
    try {
      order = await exchange.createOrder(
        resData.data.instrument_name,
        resData.data.type,
        resData.data.direction,
        resData.data.quantity,
        resData.data.price
      );

      const getOrderStatus = async () => {
        const pausedInside =
          loggedinUser?.metadata?.user_metadata?.paused || [];

        filterP_inside = pausedInside.filter((item: any) => item === +newID);

        fetchOrder = await exchange.privateGetGetOrderState({
          order_id: orderID,
        });
      };

      if (!firstOrder) {
        await getOrderStatus();
      }

      const checkOrderStatus = async () => {
        const delayedOrder = await new Promise((resolve) =>
          setTimeout(
            async () => resolve(fetchOrder),
            Number(process.env.NEXT_PUBLIC_TWAP_ORDERSTATUS_DELAY)
          )
        );
        const status = (delayedOrder as any)?.result?.order_state;

        console.log("status: ", status);

        if (selectedExchange === "deribit") {
          if (status === "filled" || status === "cancelled") {
            return false;
          } else if (status === "open") {
            return true;
          } else {
            return false;
          }
        } else {
          if (
            status === "NEW" ||
            status === "PARTIALLY_FILLED" ||
            status === "PENDING_CANCEL"
          ) {
            return true;
          } else if (status === "FILLED" || status === "CANCELED") {
            return false;
          } else {
            return false;
          }
        }
      };

      if (!firstOrder) {
        const status = fetchOrder?.result?.order_state;

        if (selectedExchange === "deribit") {
          if (status === "open") {
            console.log("status: ", "open");
            do {
              try {
                await getOrderStatus();
              } catch (error: any) {
                console.error(error.message);
                return;
              }
            } while (
              (await checkOrderStatus()) === true &&
              !filterP_inside.length
            );
          } else if (status === "filled" || status === "cancelled") {
            console.log(
              "We're not doing anything since the status is:",
              status
            );
          } else {
            return { error: `Order status is ${status}` };
          }
        } else {
          if (
            status === "NEW" ||
            status === "PARTIALLY_FILLED" ||
            status === "PENDING_CANCEL"
          ) {
            console.log("status: ", status);
            do {
              try {
                await getOrderStatus();
              } catch (error: any) {
                console.error(error.message);
                return;
              }
            } while (
              (await checkOrderStatus()) === true &&
              !filterP_inside.length
            );
          } else if (status === "FILLED" || status === "CANCELED") {
            console.log(
              "We're not doing anything since the status is:",
              status
            );
          } else {
            return { error: `Order status is ${status}` };
          }
        }
      }

      order.exit = resData.data.Exit;

      return order;
    } catch (error: any) {
      console.error(error.message);

      const err = error?.message?.replace("deribit", "").trim();
      const errJSON = err?.charAt(0);

      if (errJSON === "{") {
        return { error: `${JSON.parse(err)?.error?.message}` };
      } else {
        return { error: err };
      }
    }
  } else {
    return { error: "transaction terminated" };
  }
};

export const cancelOrder = async (data: { orderID: string }): Promise<any> => {
  const exchange = exchangeDeribit_future;

  let order;

  try {
    order = await exchange.privateGetCancel({ order_id: data.orderID });
  } catch (error: any) {
    const err = error?.message?.replace("deribit", "").trim();
    const errJSON = err?.charAt(0);

    if (errJSON === "{") {
      return { error: `${JSON.parse(err)?.error?.message}` };
    } else {
      return { error: err };
    }
  }
  return order;
};

export const getPosition = async (marketData: MarketData): Promise<any> => {
  const { market, insType, coin } = marketData;

  const marketInstance =
    market === "binance"
      ? insType === "future"
        ? exchangeBinance_future
        : insType === "spot"
        ? exchangeBinance_spot
        : insType === "option"
        ? exchangeBinance_option
        : insType === "margin"
        ? exchangeBinance_margin
        : exchangeBinance_delivery
      : insType === "future"
      ? exchangeDeribit_future
      : insType === "option"
      ? exchangeDeribit_option
      : exchangeDeribit_spot;

  const params = {
    instrument_name: coin,
  };

  console.log(params, marketInstance);

  try {
    const position =
      market === "deribit"
        ? await marketInstance.privateGetGetPosition(params)
        : await marketInstance.fetchPositions([coin]);

    return position;
  } catch (error: any) {
    const err = error.message.replace("deribit", "").trim();
    const errJSON = err.charAt(0);

    if (errJSON === "{") {
      return {
        error: `Can't get the position, ${
          JSON.parse(err)?.error?.data?.reason
        }!`,
      };
    } else {
      return { error: err };
    }
  }
};

export const getPositionDeribit = async (symbols: any): Promise<any> => {
  const marketFuture = exchangeDeribit_future;
  const marketOption = exchangeDeribit_option;

  let positionFuture: any[] = [];
  let positionOption: any[] = [];

  for (const item in symbols) {
    const paramsFuture = {
      currency: item,
      kind: "future",
    };

    const paramsOption = {
      currency: item,
      kind: "option",
    };

    try {
      const future = await marketFuture.privateGetGetPositions(paramsFuture);
      const option = await marketOption.privateGetGetPositions(paramsOption);

      if (future.result) {
        positionFuture = [...positionFuture, ...future.result];
      }
      if (option.result) {
        positionOption = [...positionOption, ...option.result];
      }
    } catch (error: any) {
      const err = error.message.replace("deribit", "").trim();
      const errJSON = err.charAt(0);

      if (errJSON === "{") {
        return {
          error: `Can't get the position, ${
            JSON.parse(err)?.error?.data?.reason
          }!`,
        };
      } else {
        return { error: err };
      }
    }
  }

  return { positionFuture, positionOption };
};

export const getSinglePositionDeribit = async (
  symbol: string,
  market: string
): Promise<any> => {
  const marketDeribit =
    market === "spot"
      ? exchangeDeribit_spot
      : market === "future"
      ? exchangeDeribit_spot
      : exchangeDeribit_option;

  const params = {
    instrument_name: symbol,
  };

  try {
    const position = await marketDeribit.privateGetGetPosition(params);

    return position?.result;
  } catch (error: any) {
    const err = error.message.replace("deribit", "").trim();
    const errJSON = err.charAt(0);

    if (errJSON === "{") {
      return {
        error: `Can't get the position, ${
          JSON.parse(err)?.error?.data?.reason
        }!`,
      };
    } else {
      return { error: err };
    }
  }
};

export const fetchAccountDeribit = async (market: string): Promise<any> => {
  const exchangeMarket =
    market === "future" ? exchangeDeribit_future : exchangeDeribit_option;

  const balanceDeribit: any = {};
  try {
    const balanceETH = await exchangeMarket.fetchBalance({ currency: "ETH" });
    const balanceBTC = await exchangeMarket.fetchBalance({ currency: "BTC" });
    const balanceUSDC = await exchangeMarket.fetchBalance({
      currency: "USDC",
    });
    const tickerBTC = await exchangeMarket.publicGetTicker({
      instrument_name: "BTC_USDC",
    });
    const tickerETH = await exchangeMarket.publicGetTicker({
      instrument_name: "ETH_USDC",
    });
    const tickerUSDC = { result: { index_price: "1" } };

    balanceDeribit.ETH = balanceETH;
    balanceDeribit.BTC = balanceBTC;
    balanceDeribit.USDC = balanceUSDC;
    balanceDeribit.tickersBTC = tickerBTC?.result;
    balanceDeribit.tickersETH = tickerETH?.result;
    balanceDeribit.tickersUSDC = tickerUSDC?.result;
  } catch (error: any) {
    const err = error.message.replace("deribit", "").trim();

    const errJSON = err.charAt(0);

    if (errJSON === "{") {
      balanceDeribit.deribit = { error: JSON.parse(err).error.message };
    } else {
      balanceDeribit.deribit = { error: err };
    }
  }

  return balanceDeribit;
};

export const fetchTickers = async (): Promise<any> => {
  let news: any;

  await axios
    .get("https://rss.app/feeds/v1.1/4Oq1Qm0ijF5T5mGE.json")
    .then((res) => {
      news = res.data;
    })
    .catch((err) => {
      news = { error: err.message };
    });

  return news;
};

export const fetchGreek = async (
  realSymbols: GreekData,
  fakeSymbols: GreekData,
  account: any[],
  slide: number,
  time: number
): Promise<any> => {
  let newData: any = {};
  const url = process.env.NEXT_PUBLIC_URLGREEK;

  await axios({
    method: "POST",
    url,
    data: {
      data: realSymbols,
      slides: [+slide],
      Time: +time,
    },
  })
    .then((res) => {
      for (const item in res.data) {
        const newObj = { ...res.data[item], instrument: item };
        newData.push(newObj);
      }
    })
    .then(async () => {
      await axios({
        method: "POST",
        url,
        data: {
          data: fakeSymbols,
          slides: [+slide],
          Time: +time,
        },
      }).then((fake) => {
        for (const item in fake.data) {
          const filterEditing = account.filter(
            (it) => it.instrument === item && it.editAmount
          );
          const newObj = { ...fake.data[item], fake: true };
          newObj.instrument = item;
          newObj.editAmount = filterEditing.length ? true : false;
          newData.push(newObj);
        }
      });
    })
    .catch((err) => {
      newData = { error: err.message };
    });

  return newData;
};

export const fetchSymbolFavorites = async (starredData: any): Promise<any> => {
  const { tab: market, starredData: data } = starredData;

  const spot = binanceSpot_public;
  const future = binanceFuture_public;
  const margin = binanceMargin_public;
  const delivery = binanceDelivery_public;
  const option = binanceOption_public;

  const deribit = deribitExchange_public;

  const binanceFavorites: any[] = [];
  const deribitFavorites: any[] = [];

  if (market === "binance") {
    if (data.length) {
      for (const item of data) {
        let exchange;

        switch (item.type) {
          case "spot":
            exchange = spot;
            break;
          case "margin":
            exchange = margin;
            break;
          case "future":
            exchange = future;
            break;
          case "delivery":
            exchange = delivery;
            break;
          default:
            exchange = option;
            break;
        }

        try {
          const ticker = await exchange.fetchTicker(item.symbol);

          binanceFavorites.push(ticker);
        } catch (error) {
          console.error(error);
        }
      }
      return binanceFavorites;
    } else {
      return [null];
    }
  } else {
    if (data.length) {
      for (const item of data) {
        try {
          const ticker = await deribit.fetchTicker(item.symbol);

          deribitFavorites.push(ticker);
        } catch (error) {
          console.error(error);
        }
      }

      return deribitFavorites;
    } else {
      return null;
    }
  }
};
