import CryptoJS from "crypto-js";
import bcrypt from "bcryptjs";
import cryptoRandomString from "crypto-random-string";
import ccxt from "ccxt";
interface EncryptedKeys {
  binancePublic: string;
  deribitPublic: string;
  binanceSecret: string;
  deribitSecret: string;
  binancePublic_specific: { [key: string]: string };
  binanceSecret_specific: { [key: string]: string };
  deribitPublic_specific: { [key: string]: string };
  deribitSecret_specific: { [key: string]: string };
  key1: string;
  key2: string;
  secretAvailable: boolean;
}

interface HashPasswordResult {
  newKey2: string;
  SHA256key2: string;
  hash: string;
}

interface SaveKeysInput {
  binancePublic?: string;
  binanceSecret?: string;
  deribitPublic?: string;
  deribitSecret?: string;
  apiTypeB?: boolean;
  apiTypeD?: boolean;
  password: string;
  username: string;
  [key: string]: any;
}

export const hashPassword = (
  data: string,
  salt: string
): HashPasswordResult => {
  const hash = bcrypt.hashSync(data, salt);
  const newKey2 = hash.substring(0, 33);
  const SHA256key2 = CryptoJS.SHA256(newKey2).toString(CryptoJS.enc.Hex);
  return { newKey2, SHA256key2, hash };
};

export const checkPassword = (data: { pwd: string }, salt: string) => {
  return hashPassword(data.pwd, salt);
};

export const decryptKeys = (e: EncryptedKeys) => {
  const decryptData = (encryptedData: string, key: string) => {
    try {
      const decrypted = CryptoJS.AES.decrypt(encryptedData, key);
      const decryptedString = decrypted?.toString(CryptoJS.enc.Utf8);

      if (!decryptedString) {
        throw new Error("Decryption failed or produced invalid UTF-8 data.");
      }

      return decryptedString;
    } catch (error: any) {
      return "";
    }
  };

  const decryptedKey1 = decryptData(e.key1, e.key2);

  const binancePublic = decryptData(e.binancePublic, decryptedKey1);
  const deribitPublic = decryptData(e.deribitPublic, decryptedKey1);
  const binanceSecret = e.secretAvailable
    ? decryptData(e.binanceSecret, decryptedKey1)
    : "";
  const deribitSecret = e.secretAvailable
    ? decryptData(e.deribitSecret, decryptedKey1)
    : "";

  const decryptSpecific = (specific: { [key: string]: string }) => {
    return Object.entries(specific).reduce((acc, [key, value]) => {
      try {
        const decrypted = CryptoJS.AES.decrypt(value, decryptedKey1);
        const decryptedString = decrypted?.toString(CryptoJS.enc.Utf8);

        if (!decryptedString) {
          throw new Error("Decryption failed or produced invalid data.");
        }

        return { ...acc, [key]: decryptedString };
      } catch (error) {
        console.error(`Error decrypting key ${key}:`, error);
        return { ...acc, [key]: null }; // Or handle the error appropriately
      }
    }, {});
  };

  const binancePublic_specific = decryptSpecific(
    e.binancePublic_specific || {}
  );
  const binanceSecret_specific = decryptSpecific(
    e.secretAvailable ? e.binanceSecret_specific || {} : {}
  );
  const deribitPublic_specific = decryptSpecific(
    e.deribitPublic_specific || {}
  );
  const deribitSecret_specific = decryptSpecific(
    e.secretAvailable ? e.deribitSecret_specific || {} : {}
  );

  const decryptedResult = {
    decryptedKey1,
    binancePublic,
    deribitPublic,
    binanceSecret,
    deribitSecret,
    binancePublic_specific,
    binanceSecret_specific,
    deribitPublic_specific,
    deribitSecret_specific,
  };

  return decryptedResult;
};

export const encryptLoginCookie = async (e: string) => {
  const salt = bcrypt.genSaltSync(10);
  const key1 = cryptoRandomString({ length: 50 });
  const hash = bcrypt.hashSync(e, salt);
  const key2 = hash.substring(0, 33);
  const newKey1 = CryptoJS.AES.encrypt(key1, key2).toString();
  const hashPwd = CryptoJS.AES.encrypt(e, key1).toString();

  return { password: hashPwd, key1: newKey1, salt, key2 };
};

export const decryptLoginCookie = async (e: {
  key1: string;
  key2: string;
  password: string;
}) => {
  if (e) {
    const decryptedKey1 = CryptoJS.AES.decrypt(e.key1, e.key2).toString(
      CryptoJS.enc.Utf8
    );
    const decryptedPwd = CryptoJS.AES.decrypt(
      e.password,
      decryptedKey1
    ).toString(CryptoJS.enc.Utf8);
    return decryptedPwd;
  }
  return null;
};

export const saveKeys = async (e: SaveKeysInput) => {
  const salt = bcrypt.genSaltSync(10);
  let key1 = cryptoRandomString({ length: 50 });

  const encryptKey = (key: string, value: string) =>
    value ? CryptoJS.AES.encrypt(value, key).toString() : "";

  const binancePublic =
    e.binancePublic && !e.apiTypeB ? encryptKey(key1, e.binancePublic) : "";
  const binanceSecret =
    e.binanceSecret && !e.apiTypeB ? encryptKey(key1, e.binanceSecret) : "";
  const deribitPublic =
    e.deribitPublic && !e.apiTypeD ? encryptKey(key1, e.deribitPublic) : "";
  const deribitSecret =
    e.deribitSecret && !e.apiTypeD ? encryptKey(key1, e.deribitSecret) : "";

  const encryptSpecific = (
    types: string[],
    prefix: string,
    apiType: string
  ) => {
    return types.reduce((acc, type) => {
      const keyPublic = `${prefix}${type}Public`;
      const keySecret = `${prefix}${type}Secret`;

      const newAcc =
        apiType === "public"
          ? {
              ...acc,
              [keyPublic]: encryptKey(key1, e[keyPublic]),
            }
          : {
              ...acc,
              [keySecret]: encryptKey(key1, e[keySecret]),
            };

      return newAcc;
    }, {});
  };

  const binanceTypes = ["Future", "Spot"];
  const deribitTypes = ["Future", "Option"];

  const binancePublic_specific = e.apiTypeB
    ? encryptSpecific(binanceTypes, "binance", "public")
    : {};
  const binanceSecret_specific = e.apiTypeB
    ? encryptSpecific(binanceTypes, "binance", "secret")
    : {};
  const deribitPublic_specific = e.apiTypeD
    ? encryptSpecific(deribitTypes, "deribit", "public")
    : {};
  const deribitSecret_specific = e.apiTypeD
    ? encryptSpecific(deribitTypes, "deribit", "secret")
    : {};

  const hash = bcrypt.hashSync(e.password, salt);
  const key2 = hash.substring(0, 33);
  key1 = CryptoJS.AES.encrypt(key1, key2).toString();
  const newKey2 = CryptoJS.SHA256(key2).toString(CryptoJS.enc.Hex);

  const encryptedJson = {
    username: e.username,
    password: hash,
    binancePublic,
    deribitSecret,
    binanceSecret,
    deribitPublic,
    salt,
    key1,
    key2,
    newKey2,
    binancePublic_specific,
    binanceSecret_specific,
    deribitPublic_specific,
    deribitSecret_specific,
  };

  return encryptedJson;
};

export const encryptData = (data: string, key2: string) => {
  let key1 = cryptoRandomString({ length: 50 });
  const encData = CryptoJS.AES.encrypt(data.trim(), key1).toString();
  key1 = CryptoJS.AES.encrypt(key1, key2.trim()).toString();
  return { instrument: encData, key1 };
};

export const decryptData = (
  data: { instrument: string; key1: string },
  key2: string
) => {
  try {
    const decryptedKey1 = CryptoJS.AES.decrypt(
      data.key1.trim(),
      key2.trim()
    ).toString(CryptoJS.enc.Utf8);
    const decryptedData = CryptoJS.AES.decrypt(
      data.instrument,
      decryptedKey1
    ).toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const checkBinanceKey = async (
  apiKey: string,
  secret: string,
  type: string,
  proxy: string,
  isTestKeyB: boolean
) => {
  apiKey = apiKey || "";
  secret = secret || "";
  proxy = proxy || "";

  let exchange;
  if (isTestKeyB) {
    exchange = new ccxt.binanceusdm({
      apiKey: apiKey.trim(),
      secret: secret.trim(),
      options: { defaultType: type?.toLowerCase() },
      urls: {
        api: {
          public: "https://testnet.binancefuture.com/fapi/v1",
          private: "https://testnet.binancefuture.com/fapi/v1",
        },
      },
    });
  } else {
    exchange = new ccxt.binance({
      proxy: proxy || process.env.NEXT_PUBLIC_CORS_PROXY_URL,
      apiKey: apiKey.trim(),
      secret: secret.trim(),
      options: { defaultType: type?.toLowerCase() },
    });
  }

  if (exchange && typeof exchange.setSandboxMode === "function") {
    exchange.setSandboxMode(isTestKeyB);
  } else {
    console.error("Exchange does not support setSandboxMode");
  }

  try {
    await exchange.fetchBalance();

    return "success";
  } catch (error: any) {
    console.error("Error fetching balance:", error);
    const err = error.message.replace("binance", "").trim();
    return err.startsWith("{")
      ? { error: JSON.parse(err).msg }
      : { error: err };
  }
};

export const checkDeribitKey = async (
  apiKey: string,
  secret: string,
  type: string,
  proxy: string,
  isTestKeyD: boolean
) => {
  apiKey = apiKey || "";
  secret = secret || "";
  proxy = proxy || "";

  const exchange = new ccxt.deribit({
    proxy: proxy || process.env.NEXT_PUBLIC_CORS_PROXY_URL,
    apiKey: apiKey.trim(),
    secret: secret.trim(),
    options: { defaultType: type?.toLowerCase() },
    urls: isTestKeyD ? { api: "https://test.deribit.com/api/v2" } : undefined,
  });

  if (typeof exchange.setSandboxMode === "function") {
    exchange.setSandboxMode(isTestKeyD);
  } else {
    console.error("Exchange does not support setSandboxMode");
  }

  try {
    await exchange.fetchBalance();

    return "success";
  } catch (error: any) {
    console.error("Error fetching balance:", error);
    const err = error.message.replace("deribit", "").trim();
    return err.startsWith("{")
      ? { error: JSON.parse(err).error.message }
      : { error: err };
  }
};
