import ccxt from "ccxt";

export let exchangeBinance_spot: any;
export let exchangeBinance_future: any;
export let exchangeBinance_delivery: any;
export let exchangeBinance_option: any;
export let exchangeBinance_margin: any;
export let exchangeDeribit_future: any;
export let exchangeDeribit_option: any;
export let exchangeDeribit_spot: any;
export let binanceSpot_public: any;
export let binanceFuture_public: any;
export let binanceMargin_public: any;
export let binanceDelivery_public: any;
export let binanceOption_public: any;
export let deribitExchange_public: any;
export let deribitSpot_public: any;

export const loadPublicCCXT = (testNetB: boolean, testNetD: boolean) => {
  const exchangeClass_binanceSpot = ccxt.binance;
  const exchangeClass_binanceFuture = ccxt.binance;
  const exchangeClass_binanceMargin = ccxt.binance;
  const exchangeClass_binanceDelivery = ccxt.binance;
  const exchangeClass_binanceOption = ccxt.binance;
  const exchangeClass_deribitPublic = ccxt.deribit;

  console.log("Loading public CCXT: ", testNetB, testNetD);

  binanceSpot_public = new exchangeClass_binanceSpot({ enableRateLimit: true });
  binanceFuture_public = new exchangeClass_binanceFuture({
    enableRateLimit: true,
  });
  binanceMargin_public = new exchangeClass_binanceMargin({
    enableRateLimit: true,
  });
  binanceDelivery_public = new exchangeClass_binanceDelivery({
    enableRateLimit: true,
  });
  binanceOption_public = new exchangeClass_binanceOption({
    enableRateLimit: true,
  });
  deribitExchange_public = new exchangeClass_deribitPublic();

  if (testNetB !== undefined && testNetB !== null) {
    binanceSpot_public.setSandboxMode(testNetB);
    binanceFuture_public.setSandboxMode(testNetB);
    binanceMargin_public.setSandboxMode(testNetB);
    binanceDelivery_public.setSandboxMode(testNetB);
    binanceOption_public.setSandboxMode(testNetB);
  }

  if (testNetD !== undefined && testNetD !== null) {
    deribitExchange_public.setSandboxMode(testNetD);
  }
};

export const resetCCXT = async (
  dataProxy: string,
  apiKey_binance: string,
  secret_binance: string,
  apiKey_deribit: string,
  secret_deribit: string,
  isTestKeyB: boolean,
  isTestKeyD: boolean,
  apiTypeB: boolean,
  apiTypeD: boolean,
  binancePublicSpecific: any,
  binanceSecretSpecific: any,
  deribitPublicSpecific: any,
  deribitSecretSpecific: any
) => {
  const initProxy = dataProxy ? dataProxy : "corsproxy.io/?";
  const searchLocal = initProxy?.toLowerCase().search("localhost");
  const finalProxy =
    searchLocal < 0 ? `https://${initProxy}` : `http://${initProxy}`;
  const exchangeClassSpot = ccxt.binance;
  const exchangeClassFuture = ccxt.binance;
  const exchangeClassDelivery = ccxt.binance;
  const exchangeClassOption = ccxt.binance;
  const exchangeClassMargin = ccxt.binance;
  const exchangeClassDeribit = ccxt.deribit;
  const exchangeClassDeribit_option = ccxt.deribit;
  const exchangeClassDeribit_spot = ccxt.deribit;

  exchangeBinance_spot = new exchangeClassSpot({
    proxy: finalProxy,
    apiKey: apiTypeB
      ? binancePublicSpecific?.binanceSpotPublic
      : apiKey_binance,
    secret: apiTypeB
      ? binanceSecretSpecific?.binanceSpotSecret
      : secret_binance,
    options: {
      defaultType: "spot",
      adjustForTimeDifference: true,
    },
    timeout: 30000,
  });

  exchangeBinance_future = new exchangeClassFuture({
    proxy: finalProxy,
    apiKey: apiTypeB
      ? binancePublicSpecific?.binanceFuturePublic
      : apiKey_binance,
    secret: apiTypeB
      ? binanceSecretSpecific?.binanceFutureSecret
      : secret_binance,
    options: {
      defaultType: "future",
      adjustForTimeDifference: true,
    },
    timeout: 30000,
  });

  exchangeBinance_delivery = new exchangeClassDelivery({
    proxy: finalProxy,
    apiKey: apiTypeB
      ? binancePublicSpecific?.binanceFuturePublic
      : apiKey_binance,
    secret: apiTypeB
      ? binanceSecretSpecific?.binanceFutureSecret
      : secret_binance,
    options: {
      defaultType: "delivery",
      adjustForTimeDifference: true,
    },
    timeout: 30000,
  });

  exchangeBinance_margin = new exchangeClassMargin({
    proxy: finalProxy,
    apiKey: apiTypeB
      ? binancePublicSpecific?.binanceSpotPublic
      : apiKey_binance,
    secret: apiTypeB
      ? binanceSecretSpecific?.binanceSpotSecret
      : secret_binance,
    options: {
      defaultType: "margin",
      fetchCurrencies: false,
      adjustForTimeDifference: true,
    },
    timeout: 30000,
  });

  exchangeBinance_option = new exchangeClassOption({
    proxy: finalProxy,
    apiKey: apiTypeB
      ? binancePublicSpecific?.binanceSpotPublic
      : apiKey_binance,
    secret: apiTypeB
      ? binanceSecretSpecific?.binanceSpotSecret
      : secret_binance,
    options: {
      defaultType: "option",
      adjustForTimeDifference: true,
    },
    timeout: 30000,
  });

  loadPublicCCXT(isTestKeyB, isTestKeyD);

  exchangeDeribit_future = new exchangeClassDeribit({
    apiKey: apiTypeD
      ? deribitPublicSpecific.deribitFuturePublic
      : apiKey_deribit,
    secret: apiTypeD
      ? deribitSecretSpecific.deribitFutureSecret
      : secret_deribit,
    options: {
      defaultType: "future",
    },
  });

  exchangeDeribit_option = new exchangeClassDeribit_option({
    apiKey: apiTypeD
      ? deribitPublicSpecific.deribitOptionPublic
      : apiKey_deribit,
    secret: apiTypeD
      ? deribitSecretSpecific.deribitOptionSecret
      : secret_deribit,
    options: {
      defaultType: "option",
    },
  });

  exchangeDeribit_spot = new exchangeClassDeribit_spot({
    apiKey: apiTypeD ? deribitPublicSpecific.deribitSpotPublic : apiKey_deribit,
    secret: apiTypeD ? deribitSecretSpecific.deribitSpotSecret : secret_deribit,
    options: {
      defaultType: "spot",
    },
  });

  console.log("Resetting CCXT: ", isTestKeyB, isTestKeyD);

  exchangeBinance_delivery.setSandboxMode(isTestKeyB);
  exchangeBinance_future.setSandboxMode(isTestKeyB);
  exchangeBinance_margin.setSandboxMode(isTestKeyB);
  exchangeBinance_spot.setSandboxMode(isTestKeyB);
  exchangeBinance_option.setSandboxMode(isTestKeyB);
  exchangeDeribit_future.setSandboxMode(isTestKeyD);
  exchangeDeribit_option.setSandboxMode(isTestKeyD);
  exchangeDeribit_spot.setSandboxMode(isTestKeyD);
};
