import ls from "local-storage";

interface Account {
  id: string;
  username: string;
  paused: any[];
  [key: string]: any;
}

interface User {
  trading?: number;
  risk?: number;
  analytics?: number;
}

export const fetchAccounts = async (): Promise<Account[]> => {
  const accounts = ls<Account[]>("accounts");
  return accounts !== null && accounts !== undefined ? accounts : [];
};

export const fetchCurrentAccount = async (
  e: string | number,
  type: string
): Promise<Account | null> => {
  const accounts = await fetchAccounts();
  const id = type === "uname" ? e : +e;

  if (accounts.length) {
    const filter =
      type === "uname"
        ? accounts.filter((item) => item.username === id)
        : accounts.filter((item) => item.id === id);

    if (filter.length) {
      return filter[0];
    }
  }
  return null;
};

export const fetchCurrentUser = async (): Promise<User | null> => {
  const currentUser = ls<User>("currentUser");
  return currentUser !== undefined ? currentUser : null;
};

export const inputCurrentUser = async (
  e: number | string,
  type: string
): Promise<string> => {
  let user: any = ls<User>("currentUser");

  if (user) {
    user[type] = e;
  } else {
    user = { [type]: e };
  }

  ls("currentUser", user);
  return "success";
};

export const inputAccounts = async (e: Account[]): Promise<string> => {
  ls("accounts", e);
  return "success";
};

export const inputCurrentAccount = async (e: Account): Promise<string> => {
  const accounts = await fetchAccounts();
  const filter = accounts.filter((item) => +item.id === +e.id);
  const newArr = filter.length
    ? accounts.map((item) => (+item.id === +e.id ? e : item))
    : [...accounts, e];

  await inputAccounts(newArr);
  return "success";
};

export const fetchLastID = async (): Promise<number | undefined> => {
  const userID = await fetchCurrentUser();
  const user = await fetchCurrentAccount(userID?.trading!, "");
  return user?.lastID;
};

export const inputLastID = async (e: number): Promise<string> => {
  const userID = await fetchCurrentUser();
  const tradingID = userID?.trading ?? 0;
  const accounts = await fetchAccounts();
  const newArr = accounts.map((item) =>
    +item.id === +tradingID ? { ...item, lastID: +e } : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const fetchCurrentPaused = async (uniqueID: string): Promise<any> => {
  const accounts = await fetchAccounts();
  const filterAccount = accounts.find((item) => item.username === uniqueID);
  return filterAccount?.paused;
};

export const fetchCurrentCancelled = async (
  uniqueID: string
): Promise<any[]> => {
  const accounts = await fetchAccounts();
  const filterAccount = accounts.find((item) => item.username === uniqueID);
  return filterAccount?.cancelled;
};

export const fetchCurrentAlgos = async (uniqueID: string): Promise<any[]> => {
  const accounts = await fetchAccounts();
  const filterAccount = accounts.find((item) => item.username === uniqueID);
  return filterAccount?.algos;
};

export const inputAlgos = async ({
  uniqueID,
  algosToSave,
}: {
  uniqueID: string;
  algosToSave: any[];
}): Promise<string> => {
  const accounts = await fetchAccounts();
  const newArr = accounts.map((item) => {
    return item.username === uniqueID ? { ...item, algos: algosToSave } : item;
  });

  await inputAccounts(newArr);
  return "success";
};

export const inputPaused = async ({
  uniqueID,
  idsToBePaused,
}: {
  uniqueID: string;
  idsToBePaused: number[];
}): Promise<string> => {
  const accounts = await fetchAccounts();
  const newArr = accounts.map((item) => {
    console.log(item.paused);
    return item.username === uniqueID
      ? { ...item, paused: idsToBePaused }
      : item;
  });

  await inputAccounts(newArr);
  return "success";
};

export const inputCancel = async (e: any[]): Promise<string> => {
  const userID = await fetchCurrentUser();
  const tradingID = userID?.trading ?? 0;
  const accounts = await fetchAccounts();
  const newArr = accounts.map((item) =>
    +item.id === +tradingID ? { ...item, cancelled: e } : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const inputLoadedSettings = async (e: any): Promise<string> => {
  ls("loadedSettings", e);
  return "success";
};

export const fetchVolBump = async (e: string): Promise<any[]> => {
  const userID = await fetchCurrentUser();
  const userType = e === "risk" ? userID?.risk : userID?.trading;
  const user = await fetchCurrentAccount(userType!, "");
  return user?.volBumps || [];
};

export const inputVolBump = async (e: any[], type: string): Promise<string> => {
  const userID = await fetchCurrentUser();
  const userType = type === "risk" ? +userID?.risk! : +userID?.trading!;
  const accounts = await fetchAccounts();
  const newArr = accounts.map((item) =>
    +item.id === userType ? { ...item, volBumps: e } : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const inputSlideVal = async (
  e: number,
  type: string
): Promise<string> => {
  const userID = await fetchCurrentUser();
  const accounts = await fetchAccounts();
  const userType = type === "risk" ? +userID?.risk! : +userID?.trading!;
  const newArr = accounts.map((item) =>
    +item.id === userType ? { ...item, slide: e } : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const inputTimeVal = async (
  e: number,
  type: string
): Promise<string> => {
  const userID = await fetchCurrentUser();
  const accounts = await fetchAccounts();
  const userType = type === "risk" ? +userID?.risk! : +userID?.trading!;
  const newArr = accounts.map((item) =>
    +item.id === userType ? { ...item, timeVal: e } : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const fetchSlideVal = async (
  type: string
): Promise<number | undefined> => {
  const userID = await fetchCurrentUser();
  const userType = type === "risk" ? +userID?.risk! : +userID?.trading!;
  const user = await fetchCurrentAccount(userType, "");
  return user?.slide;
};

export const fetchTimeVal = async (
  type: string
): Promise<number | undefined> => {
  const userID = await fetchCurrentUser();
  const userType = type === "risk" ? +userID?.risk! : +userID?.trading!;
  const user = await fetchCurrentAccount(userType, "");
  return user?.timeVal;
};

export const fetchTableViews = async (
  type: string,
  tableType: string
): Promise<any[]> => {
  const userID = await fetchCurrentUser();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const user = await fetchCurrentAccount(userType, "");
  return user ? user[`tableViews${tableType}`] : [];
};

export const inputTableViews = async (
  e: any[],
  type: string,
  tableType: string
): Promise<string> => {
  const userID = await fetchCurrentUser();
  const accounts = await fetchAccounts();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const newArr = accounts.map((item) =>
    +item.id === userType ? { ...item, [`tableViews${tableType}`]: e } : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const fetchTableViewsDashboard = async (
  type: string,
  tableType: string
): Promise<any[]> => {
  const userID = await fetchCurrentUser();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const user = await fetchCurrentAccount(userType, "");
  return user ? user[`tableViewsDashboard${tableType}`] : [];
};

export const inputTableViewsDashboard = async (
  e: any[],
  type: string,
  tableType: string
): Promise<string> => {
  const userID = await fetchCurrentUser();
  const accounts = await fetchAccounts();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const newArr = accounts.map((item) =>
    +item.id === userType
      ? { ...item, [`tableViewsDashboard${tableType}`]: e }
      : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const inputLockSettings = async (
  pwd: string,
  time: number,
  type: string
): Promise<string> => {
  const userID = await fetchCurrentUser();
  const accounts = await fetchAccounts();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const newArr = accounts.map((item) =>
    +item.id === userType
      ? { ...item, lockscreen: { password: pwd, time } }
      : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const fetchLockScreen = async (
  type: string
): Promise<{ password: string; time: number } | undefined> => {
  const userID = await fetchCurrentUser();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const user = await fetchCurrentAccount(userType, "");
  return user?.lockscreen;
};

export const inputAnalyticsDashboard = async (
  e: any,
  type: string
): Promise<string> => {
  const userID = await fetchCurrentUser();
  const accounts = await fetchAccounts();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const newArr = accounts.map((item) =>
    +item.id === userType ? { ...item, analyticsDashboard: e } : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const fetchAnalyticsDashboard = async (type: string): Promise<any> => {
  const userID = await fetchCurrentUser();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const user = await fetchCurrentAccount(userType, "");
  return user?.analyticsDashboard;
};

export const fetchAnalyticsDashLayout = async (type: string): Promise<any> => {
  const userID = await fetchCurrentUser();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const user = await fetchCurrentAccount(userType, "");
  return user?.analyticsDashboardLayout;
};

export const fetchAnalyticsDashSimplify = async (
  type: string
): Promise<any> => {
  const userID = await fetchCurrentUser();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const user = await fetchCurrentAccount(userType, "");
  return user?.analyticsDashboardSimplify;
};

export const inputAnalyticsDashSimplify = async (
  e: any,
  type: string
): Promise<string> => {
  const userID = await fetchCurrentUser();
  const accounts = await fetchAccounts();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const newArr = accounts.map((item) =>
    +item.id === userType ? { ...item, analyticsDashboardSimplify: e } : item
  );

  await inputAccounts(newArr);
  return "success";
};

export const fetchAnalyticsFullscreen = async (type: string): Promise<any> => {
  const userID = await fetchCurrentUser();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const user = await fetchCurrentAccount(userType, "");
  return user?.analyticsDashboardFullscreen;
};

export const inputAnalyticsDashFullscreen = async (
  e: any,
  type: string
): Promise<string> => {
  const userID = await fetchCurrentUser();
  const accounts = await fetchAccounts();
  const userType =
    type === "risk"
      ? +userID?.risk!
      : type === "analytics"
      ? +userID?.analytics!
      : +userID?.trading!;
  const newArr = accounts.map((item) =>
    +item.id === userType ? { ...item, analyticsDashboardFullscreen: e } : item
  );

  await inputAccounts(newArr);
  return "success";
};
